import React, { useState } from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useForm } from 'react-hook-form';

import { regexEmail } from '#helpers/regex';
import { postSuscriptionNews } from '#api/enrollment';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const dataNews = [
  {
    title: 'María Vélez de Crack the Code: ',
    description: '“Educar a los niños en tecnología es fundamental”',
    srcImg: '/home/news/news-1.webp'
  },
  {
    title: 'Crack the Code: ',
    description: '“La inteligencia artificial puede mejorar el aprendizaje estudiantil”',
    srcImg: '/home/news/news-2.webp'
  },
  {
    title: 'Barranquilla: ',
    description:
      '“Le apuestan a la formación de más de 3 mil estudiantes en programación web”',
    srcImg: '/home/news/news-3.webp'
  }
];

const Suscription = () => {
  const {
    register,
    handleSubmit,

    reset,
    clearErrors,
    formState: { errors }
  } = useForm({ mode: 'onChange' });
  const [notificationForm, setNotificationForm] = useState({
    isVisible: false,
    message: ''
  });

  const onSubmit = async params => {
    try {
      const response = await postSuscriptionNews(params);
      if (response.status === 200) {
        reset();
        clearErrors();
        setNotificationForm({
          isVisible: true,
          message: '¡Gracias por suscribirte!'
        });
        setTimeout(() => {
          setNotificationForm({
            isVisible: false,
            message: ''
          });
        }, 4000);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <section className={styles.container}>
      {/* suscription */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx(styles.suscription)}>
          <img src="/home/suscription/suscription.webp" alt="" />
          <div className={cx(styles.text)}>
            <Typography className={cx(styles.title)} tag="h2">
              Sigamos conectados
            </Typography>
            <Typography className={cx(styles.description)} tag="p">
              Suscribete a nuestro newsletter para recibir las últimas novedades sobre lo
              que hacemos
            </Typography>
          </div>
          <div className={styles.form}>
            <div className={cx(styles.toast)}></div>
            <div className={styles.containerInput}>
              <input
                className={cx(styles.input, 'ntbau')}
                placeholder="Ingresa tu mail"
                type="text"
                id="email"
                name="email"
                ref={register({
                  required: 'Este campo es requerido',
                  pattern: {
                    value: regexEmail,
                    message: 'Formato de mail inválido'
                  }
                })}
              />
              <Button
                className={styles.button}
                type="submit"
                variant="aqua"
                textOptions={{ fontSize: [20, 20] }}
              >
                Suscribete
              </Button>
            </div>
            <div className={cx(styles.toast)}>
              {errors?.email?.message && (
                <span style={{ color: '#C00808' }}>{errors?.email?.message}</span>
              )}
              {notificationForm.isVisible && !errors?.email?.message ? (
                <span style={{ color: '#171A1A' }}>{notificationForm.message}</span>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Suscription;
